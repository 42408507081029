import axios from 'axios'
import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useCallback, useEffect } from 'react'
import { HomeIconLogin } from 'src/components/Icons/HomeIconLogin'

import Logo from '../../../images/decathlon-logo-vp-DDH3S1xy.svg'

import '../../../temp/authorize.scss'

export default function MemberAuthorize({ ...props }) {
  const getAccessToken = useCallback(
    async (authorizationCode: string, returnUrl: string) => {
      const redirectToAccessToken = `/api/oauth/finish?code=${authorizationCode}${
        returnUrl ? `&returnUrl=${returnUrl}` : ''
      }`

      const response = await axios.get(redirectToAccessToken)

      response.data.cookies.forEach((cookie: string) => {
        document.cookie = cookie
      })

      window.location.href = response.data.redirect
    },
    []
  )

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(props.location.search)
    const code = urlSearchParams.get('code') as string
    const state = urlSearchParams.get('state') as string
    const returnUrl = `${window.location.origin}/account`

    try {
      if (code && state) {
        getAccessToken(code, state)

        return
      }

      window.location.href = `/api/io/login?returnUrl=${returnUrl}`
    } catch (error) {
      throw new Error('Erro ao realizar login', error)
    }
  }, [getAccessToken, props.location])

  return (
    <>
      <GatsbySeo />
      <div className="memberAuthorize">
        <div className="overlay">
          <div className="loaderContainer" />
        </div>
        <header className="header">
          <div className="header__item">
            <Link to="/">
              <HomeIconLogin />
              <span>Voltar ao site</span>
            </Link>
          </div>
          <div className="header__item logo">
            <img src={Logo} alt="decathlon logo" />
          </div>
          <div className="header__item" />
        </header>
      </div>
    </>
  )
}
